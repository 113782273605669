.smallSize {
  .ant-upload {
    width: 35px !important;
    height: 35px !important;
    margin: 0 !important;
  }

  .ant-upload-list-picture .ant-upload-list-item {
    height: 35px !important;
    margin: 0 !important;
  }

  .ant-upload-list-picture .ant-upload-list-item-thumbnail,
  .ant-upload-list-picture .ant-upload-list-item-thumbnail img {
    height: 100% !important;
    top: 0 !important;
    padding: 2px 0;
  }

  .ant-upload-list-picture .ant-upload-list-item-thumbnail img {
    height: 100% !important;
    width: auto !important;
  }

  .ant-upload-list-picture .ant-upload-list-item-name {
    line-height: 1.3;
    padding-left: 35px;
  }

  .ant-upload-list-item-card-actions.picture {
    top: 8px;
  }
}

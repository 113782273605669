.searchBar {
  background-color: white !important;
  border-radius: 10px !important;
  margin-left: -32px !important;
  height: 36px !important;
  width: 400px !important;

  > div {
    > input {
      height: 36px !important;
    }
    > span {
      height: 36px !important;
      line-height: 45px !important;
    }
  }
}

@media screen and (max-width: 1084px) {
  .searchBar {
    width: 300px !important;
  }
}

@media screen and (max-width: 992px) {
  .searchBar {
    margin-left: 10px !important;
  }
}
